
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { DefaultSeo } from 'next-seo';
import Aos from "aos";
import "aos/dist/aos.css";
import "../styles/index.scss";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import ScrollToTop from "../components/common/ScrollTop";
import { Provider } from "react-redux";
import { store } from "../app/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import * as gtm from '../lib/gtm';
import * as gtag from '../lib/gtag';
import useTranslation from "next-translate/useTranslation";
import CookieBanner from '../components/common/CookieBanner';
import GoogleAnalytics from '../app/GoogleAnalytics';
import GoogleTagManager from '../app/GoogleTagManager';
if (typeof window !== "undefined") {
    require("bootstrap/dist/js/bootstrap");
}
// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development')
                posthog.debug();
        }
    });
}
;
const MyApp = ({ Component, pageProps }) => {
    const router = useRouter();
    const [consent, setConsent] = useState(typeof window !== "undefined" ? localStorage.getItem("consent") : null);
    const { t } = useTranslation("common");
    const checkConsent = () => {
        let decodedCookie = decodeURIComponent(document.cookie);
        decodedCookie = decodedCookie.split(';');
        decodedCookie = decodedCookie.find((cookie) => {
            return cookie.indexOf('CookieConsent') !== -1;
        });
        if (!decodedCookie) {
            return false;
        }
        if (decodedCookie && decodedCookie.includes('true')) {
            return true;
        }
        return false;
    };
    const acceptCookieConsent = () => {
        setConsent(true);
        localStorage.setItem("consent", true);
        {
            gtag.GA_TRACKING_ID && (ReactGA.initialize(gtag.GA_TRACKING_ID));
        }
    };
    useEffect(() => {
        if (checkConsent()) {
            acceptCookieConsent(true);
        }
    }, []);
    // Track page views for google analytics
    useEffect(() => {
        if (consent) {
            const handleRouteChange = (url) => {
                if (gtag.GA_TRACKING_ID) {
                    gtm.pageview(url);
                    ReactGA.send({ hitType: "pageview", page: url });
                }
                posthog?.capture('$pageview');
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events]);
    // aos animation activation
    useEffect(() => {
        Aos.init({
            duration: 1400,
            once: true
        });
    }, []);
    // rb2b script
    useEffect(() => {
        // Directly adding your script content here
        !function () {
            var reb2b = window.reb2b = window.reb2b || [];
            if (reb2b.invoked)
                return;
            reb2b.invoked = true;
            reb2b.methods = ["identify", "collect"];
            reb2b.factory = function (method) {
                return function () {
                    var args = Array.prototype.slice.call(arguments);
                    args.unshift(method);
                    reb2b.push(args);
                    return reb2b;
                };
            };
            for (var i = 0; i < reb2b.methods.length; i++) {
                var key = reb2b.methods[i];
                reb2b[key] = reb2b.factory(key);
            }
            reb2b.load = function (key) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.async = true;
                script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
                var first = document.getElementsByTagName("script")[0];
                first.parentNode.insertBefore(script, first);
            };
            reb2b.SNIPPET_VERSION = "1.0.1";
            reb2b.load(process.env.NEXT_PUBLIC_RB2B_ID);
        }();
    }, []);
    return (<Provider store={store}>
      <GoogleAnalytics />
      <GoogleTagManager />
      <DefaultSeo titleTemplate={`%s | ${t('title')}`} defaultTitle={t('seo.title')} description={t('seo.title')} openGraph={{
            type: 'website',
            locale: 'pl_PL',
            url: 'https://headhunti.pl/',
            siteName: 'HeadHunti',
            images: [
                {
                    url: `https://headhunti.pl/images/logo_headhunti_og.png`,
                    width: 1200,
                    height: 630,
                    alt: t('seo.title')
                }
            ]
        }} twitter={{
            handle: '@handle',
            site: '@site',
            cardType: 'summary_large_image'
        }}/>
      <div className="page-wrapper">
        <PostHogProvider client={posthog}>
          <CookieBanner acceptCookieConsent={acceptCookieConsent}/>
          <Component {...pageProps}/>
        </PostHogProvider>

        {/* Toastify */}
        <ToastContainer position="bottom-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
        {/* <!-- Scroll To Top --> */}
        <ScrollToTop />
      </div>
    </Provider>);
};
const __Next_Translate__Page__19103bf1d90__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19103bf1d90__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  